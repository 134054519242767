import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/**
 * Can be used as a route guard to prove whether the access is allowed to any route,
 * or restricted only to finished offers
 */
@Injectable()
export class NotLockedGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromRoot.getUserState).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        return !AuthenticationService.isOnlyAllowedToSeeFinishedOffersReasonLocked(userState.userInfo);
      }));
  }
}
